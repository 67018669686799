function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.starts-with.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import { useAuthStore } from '~/stores/auth';
var initialStates = {
  theme: true,
  closeSubscription2: false,
  contentLocale: 'en',
  closeSubscription: false,
  closeTrial: false,
  mapHelpClosed: false,
  coursesMapsTipClosed: false,
  closeSales: false,
  closeIncubator: false,
  closeRevision: false,
  closeValentines2024: false,
  closePriceChange: false,
  closeSpring2024: false,
  closeEasterDeals: false,
  closeAugustSale: false,
  closeAutumnSale: false,
  cookieConsent: encodeURIComponent(JSON.stringify({
    consent: false,
    essential: true,
    performance: false,
    marketing: false
  }))
};
var maxAge = 365 * 24 * 60 * 60;
export var useCookieStore = defineStore({
  id: 'cookie',
  state: function state() {
    var state = Object.assign({
      hydrated: false
    }, initialStates);
    return state;
  },
  getters: {
    getCookieConsent: function getCookieConsent(state) {
      var value = decodeURIComponent(state.cookieConsent);
      value = JSON.parse(value);
      return value;
    },
    cookieConsentVisible: function cookieConsentVisible(state) {
      var value = decodeURIComponent(state.cookieConsent);
      value = JSON.parse(value);
      return !value.consent;
    },
    isUkrainian: function isUkrainian(state) {
      var locales = state.contentLocale.split('.');
      return locales.includes('uk');
    }
  },
  actions: {
    setConsent: function setConsent(_ref) {
      var marketing = _ref.marketing,
        performance = _ref.performance;
      var consent = {
        consent: true,
        essential: true,
        marketing: marketing || false,
        performance: performance || false
      };
      this.cookieConsent = encodeURIComponent(JSON.stringify(consent));
      var cookie = useCookie('cookieConsent', {
        maxAge: maxAge,
        encode: function encode(val) {
          return val;
        } // @see: https://nuxt.com/docs/api/composables/use-cookie#encode
      });
      cookie.value = this.cookieConsent;

      // Change FB Pixel consent
      // @see: https://developers.facebook.com/docs/meta-pixel/implementation/gdpr
      if (this.$nuxt.$fbPixel) {
        this.$nuxt.$fbPixel.consent(marketing);
      }

      // Change Google consent
      // @see: https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced
      if (this.$nuxt.$gtag) {
        this.$nuxt.$gtag('consent', 'update', {
          'ad_storage': marketing ? 'granted' : 'denied',
          'ad_user_data': marketing ? 'granted' : 'denied',
          'ad_personalization': marketing ? 'granted' : 'denied',
          'analytics_storage': performance ? 'granted' : 'denied',
          'functionality_storage': 'granted',
          'security_storage': 'granted',
          'personalization_storage': performance ? 'granted' : 'denied'
        });
      }

      // Change HotJar consent
      if (this.$nuxt.$hotJar) {
        if (performance) this.$nuxt.$hotJar.enable();else this.$nuxt.$hotJar.disable();
      }
      this.$nuxt.$axios.$post('/cookieconsent/create', consent).catch(function (error) {
        return console.log(error);
      });

      // Remove all GA cookies
      if (!marketing && !performance) {
        var vanilaCookie = useVanilaCookie();
        vanilaCookie.removeCookiesStartingWith('_ga');
      }
    },
    setState: function setState(key, val) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this[key] = val;
      var cookie = useCookie(key, {
        maxAge: options.maxAge || maxAge
      });
      cookie.value = val;
    },
    // Server side only
    hydrate: function hydrate(req, res) {
      var _this = this,
        _auth$user,
        _auth$user2;
      if (!req || !res) {
        return console.log('[CASES] Warn: attempting to run cookieStore.hydrate without req or res');
      }
      var cookies = req.headers.cookie ? req.headers.cookie.split('; ').map(function (stringCookie) {
        return stringCookie.split('=');
      }) : [];
      var _loop = function _loop(key) {
        var cookie = cookies.find(function (cookie) {
          return cookie[0] === key;
        });

        // Value stored in cookie already
        if (cookie && cookie[1]) {
          switch (cookie[1]) {
            case "true":
              _this[key] = true;
              break;
            case "false":
              _this[key] = false;
              break;
            default:
              _this[key] = cookie[1];
          }
          // Save default value to cookie
        } else {
          var val = initialStates[key];

          // Get define contentLocale
          if (key == 'contentLocale') {
            val = _this.getContentLocale(req);
            _this.contentLocale = val; // Save to storage
          }
          _this.setCookie(res, key, val);
        }
      };
      for (var key in initialStates) {
        _loop(key);
      }
      var auth = useAuthStore();

      // Restore contentLocale for registered users
      if ((_auth$user = auth.user) !== null && _auth$user !== void 0 && _auth$user.contentLocale) {
        this.contentLocale = auth.user.contentLocale.join('.');
        this.setCookie(res, 'contentLocale', this.contentLocale);
      }

      // Restore cookie consent for registered users
      if ((_auth$user2 = auth.user) !== null && _auth$user2 !== void 0 && _auth$user2.cookieConsent) {
        var consent = {
          consent: auth.user.cookieConsent.consent,
          essential: true,
          marketing: auth.user.cookieConsent.marketing,
          performance: auth.user.cookieConsent.performance
        };
        this.cookieConsent = encodeURIComponent(JSON.stringify(consent));
        this.setCookie(res, 'cookieConsent', this.cookieConsent);
      }
      this.hydrated = true;
    },
    getContentLocale: function getContentLocale(req) {
      if (req.headers['geoip-country-code'] == 'UA') {
        return 'uk.en';
      }
      var contentLocales = [];
      if (req.headers['accept-language']) {
        var locales = req.headers['accept-language'].split(',');
        var _iterator = _createForOfIteratorHelper(locales),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var locale = _step.value;
            var code = locale.toLowerCase().substring(0, 2);
            if (['uk', 'en'].includes(code) && !contentLocales.includes(code)) {
              contentLocales.push(code);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      if (!contentLocales.includes('en')) contentLocales.push('en');
      return contentLocales.join('.');
    },
    setCookie: function setCookie(res, name, value) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      // Build the new cookie string
      var cookieString = "".concat(name, "=").concat(value, "; Path=").concat(options.path || '/', "; Max-Age=").concat(options.maxAge || maxAge);

      // Add SameSite and Secure attributes if provided
      cookieString += "; SameSite=".concat(options.sameSite || 'Lax');
      if (options.secure) cookieString += '; Secure';

      // Retrieve any existing Set-Cookie headers
      var existingCookies = res.getHeader('Set-Cookie') || [];
      if (typeof existingCookies === 'string') {
        existingCookies = [existingCookies];
      }

      // Check if the cookie with the same name already exists and replace it
      var updatedCookies = existingCookies.filter(function (cookie) {
        return !cookie.startsWith("".concat(name, "="));
      });
      updatedCookies.push(cookieString);

      // Set all cookies back as headers
      res.setHeader('Set-Cookie', updatedCookies);
    }
  }
});